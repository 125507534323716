import styled from "styled-components"
import { media } from "styles/media"

export const StyledTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme, light }) => (light ? "#fff" : theme.color.dark2)};
  line-height: 39px;
  ${media.pc} {
    font-size: ${props => props.theme.font.xl};
    letter-spacing: 0.7px;
  }
`
