import React from "react"
import { ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import GlobalStyles from "styles/GlobalStyles"
import { theme } from "styles/theme"
import Navbar from "./nav"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
