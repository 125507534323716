import React from "react"
import styled from "styled-components"
import callicon from "images/ui/call-icon.svg"

export const StyledButton = styled.button`
  background-image: ${props => props.theme.color.orange};
  color: #fff;
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px 0 rgba(255, 255, 255, 0.5), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #dd4c40;
  padding: 10px 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  text-decoration: none;
  cursor: pointer;
  & p {
    color: #fff;
  }
  & img {
    margin-right: 12px;
    max-width: 24px;
  }
  &:hover {
    transform: scale(1.08);
    color: #fff !important;
    text-decoration: none;
  }
  &:not([href]):hover {
    color: #fff !important;
  }
`

const Button = ({ children, call, cennik, tag, link, to }) => {
  return (
    <StyledButton as={tag || null} href={link || null} to={to}>
      {call && <img src={callicon} alt="Zadzwoń" />}
      {cennik && <p>$</p>}
      {children}
    </StyledButton>
  )
}

export default Button
