import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { media } from "styles/media"
import styled from "styled-components"
import Img from "gatsby-image"
import { StyledTitle } from "components/styled/styledTitle"
import Button, { StyledButton } from "components/styled/styledButton"
import { StyledSubtitle } from "components/styled/styledSubtitle"

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 31px 0;
  & ${StyledButton} {
    padding: 14px 24px;
    font-size: 20px;
    width: -webkit-fill-available;
    color: #fff;
    ${media.pc} {
      width: fit-content;
      padding: 17px 49px;
      margin-bottom: 50px;
    }
  }
  ${media.pc} {
    & .box {
      display: grid;
      grid-template-columns: 3fr 5fr;
      grid-column-gap: 30px;
      align-items: center;
    }
  }
`

const ExtendStyledSubtitle = styled(StyledSubtitle)`
  display: none;
  ${media.pc} {
    display: block;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 6px;
  }
`

const Hours = styled.div`
  margin: 32px 0;
  & p {
    font-size: ${props => props.theme.font.xs};
    letter-spacing: 0.2px;
    font-weight: 500;
    ${media.pc} {
      font-size: 20px;
    }
  }
  & p:first-of-type {
    color: #167ee6;
    margin-top: 12px;
  }
  & p:last-of-type {
    color: rgba(255, 255, 255, 0.3);
    margin-top: 4px;
    ${media.pc} {
      margin-top: 20px;
    }
  }
`

const Copy = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 8px;
  grid-template-rows: repeat(4, 1fr);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  padding-bottom: 12px;
  ${media.pc} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-row-gap: 20px;
    margin-top: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 24px;
  }
  & p,
  a {
    color: #fff;
    font-size: ${props => props.theme.font.xxs};
    opacity: 0.3;
    text-decoration: none;
    line-height: normal;
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) {
      text-align: right;
      ${media.pc} {
        text-align: center;
      }
    }
    ${media.pc} {
      text-align: center;
      &:nth-child(6) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    }
  }
`

const Nismedia = styled.p`
  margin-top: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  font-size: ${props => props.theme.font.xxs};
  ${media.pc} {
    margin-top: 24px;
    font-size: 14px;
  }
`

const ImageWrapper = styled.a`
  display: block;
  &.mobile-map {
    ${media.pc} {
      display: none;
    }
  }
  &.pc-map {
    display: none;
    ${media.pc} {
      display: block;
    }
  }
`

const Footer = () => {
  const { mobile, desktop, polityka } = useStaticQuery(graphql`
    query {
      polityka: allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      mobile: file(relativePath: { eq: "maps/mobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktop: file(relativePath: { eq: "maps/desktop.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledFooter>
      <div className="container box">
        <div>
          <ExtendStyledSubtitle>Kontakt</ExtendStyledSubtitle>
          <StyledTitle light>Otwarty dla Ciebie</StyledTitle>
          <Hours>
            <Button tag="a" call link="tel:748311910">
              Umów wizytę
            </Button>
            <p>Pon - Pt — od 9:00 do 18:00</p>
            <p>Sob - Ndz — Zamknięte</p>
          </Hours>
        </div>
        <ImageWrapper
          href="https://maps.app.goo.gl/h9MzQU4csXGt6tKj8"
          target="_blank"
          rel="noreferrer noopener"
          className="mobile-map"
        >
          <Img fluid={mobile.childImageSharp.fluid} alt="map-mobile" title="Prowadź" />
        </ImageWrapper>
        <ImageWrapper
          href="https://maps.app.goo.gl/h9MzQU4csXGt6tKj8"
          target="_blank"
          rel="noreferrer noopener"
          className="pc-map"
        >
          <Img fluid={desktop.childImageSharp.fluid} alt="map-pc" title="Prowadź" />
        </ImageWrapper>
      </div>
      <div className="container">
        <Copy>
          <Link to="/">Strona główna</Link>
          <Link to="/cennik/">Cennik</Link>
          <a href={polityka.edges[0].node.publicURL} download>
            Polityka prywatności
          </a>
          <Link to="/gabinet/">Gabinet</Link>
          <Link to="/hobby/">Hobby</Link>
          <p>Kobadent @ 2020</p>
        </Copy>
        <Nismedia>
          Designed and developed with love by{" "}
          <a href="https://nismedia.pl/" target="_blank" rel="noopener noreferrer">
            nismedia.pl
          </a>
        </Nismedia>
      </div>
    </StyledFooter>
  )
}

export default Footer
