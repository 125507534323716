import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "styles/media"
import "styles/hamburgers.css"
import callicon from "images/ui/call-icon.svg"
import Logo from "images/logo/logo.inline.svg"
import { StyledButton } from "components/styled/styledButton"

const Paragraphs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${media.pc} {
    justify-content: flex-end;
    width: auto;
    & p:first-child {
      margin-right: 8px;
    }
  }
`

const NavBanner = styled.div`
  background-color: ${props => props.theme.color.primary};
  padding: 0 16px;
  display: none;
  & .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .mail-kobadent {
      display: none;
    }
    & p {
      font-size: 12px;
      color: #fff;
    }
  }
  ${media.tablet} {
    display: block;
  }
  ${media.pc} {
    & .box {
      max-width: 996px;
      margin: 0 auto;
      & .mail-kobadent {
        font-size: 12px;
        display: block;
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  ${media.pcBig} {
    & .box {
      max-width: 1200px;
    }
  }
`

const MainNavigation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  ${media.tablet} {
    justify-content: flex-end;
  }
  ${media.pc} {
    padding: 10px 0;
    grid-template-columns: auto 1fr auto;
  }
  ${media.pcBig} {
    grid-template-columns: auto 1fr auto auto;
  }
`
const StyledNav = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${props => props.theme.color.secondary};
  transition: box-shadow 0.2s ease;
  &.fixed-bg {
    box-shadow: 0 2px 4px 0 rgba(6, 41, 100, 0.1), 0 8px 16px 0 rgba(58, 70, 91, 0.1);
  }
`

const StyledLogo = styled(Logo)`
  max-width: 180px;
  width: 100%;
  ${media.pc} {
    max-width: 204px;
  }
`

const NavItems = styled.ul`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 0;
  transform: translateY(-100%);
  position: relative;
  z-index: -1;
  display: none;
  transition: all 0.3s ease;
  opacity: 0;
  &.pc-menu {
    display: none;
  }
  ${media.pc} {
    margin-left: 44px;
    align-items: center;
  }
  ${media.pc} {
    display: none;
    &.pc-menu {
      padding: 0 0 0 16px;
      display: flex;
      flex-direction: row;
      opacity: 1;
      transform: translateY(0);
      z-index: 999;
      border-bottom: none;
    }
  }
  &.active {
    display: flex;
    transform: translateY(0);
    opacity: 1;
    z-index: 999;
    ${media.pc} {
      display: none;
    }
  }
  & li {
    width: 100%;
    ${media.pc} {
      width: auto;
    }
    & a {
      padding: 0 0 16px 0;
      font-size: 24px;
      text-decoration: none;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.2s ease;
      width: 100%;
      text-align: center;
      &:hover {
        transform: scale(1.08);
      }
      ${media.pc} {
        font-size: 16px;
        padding: 12px 20px;
        text-align: left;
        width: auto;
      }
    }
  }
`

const Button = styled(StyledButton)`
  color: #fff !important;
  display: none;
  width: fit-content;

  ${media.pc} {
    display: block;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &.mobile-btn {
    margin-top: 16px;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    padding: 14px 12px;
    width: -webkit-fill-available;
    ${media.pc} {
      display: none;
    }
  }
`

const ContactNumber = styled.p`
  font-size: 20px;
  color: #fff;
  display: none;
  margin-right: 30px;
  font-weight: 700;
  ${media.pcBig} {
    display: block;
  }
`

const Navigation = () => {
  const [isActiveNav, setActiveNav] = useState(false)

  const listenToScroll = () => {
    const position = window.pageYOffset
    if (position > 50) {
      refNav.current.classList.add("fixed-bg")
    } else {
      refNav.current.classList.remove("fixed-bg")
    }
  }

  const refNav = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  })

  return (
    <StyledNav ref={refNav}>
      <NavBanner>
        <div className="box">
          <Paragraphs>
            <p>58-200 Dzierżoniów, Sienkiewicza 14b/2</p>
            <p>Czynne: pon-pt 9:00 - 18:00</p>
          </Paragraphs>
          <a href="kobadent@kobadent.pl" className="mail-kobadent">
            kobadent@kobadent.pl
          </a>
        </div>
      </NavBanner>
      <div className="container">
        <MainNavigation>
          <Link to="/">
            <StyledLogo />
          </Link>
          <button
            className={["hamburger", "hamburger--collapse", isActiveNav && "is-active"].join(" ")}
            type="button"
            onClick={() => setActiveNav(!isActiveNav)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <NavItems className="pc-menu">
            <li>
              <Link to="/">Strona główna</Link>
            </li>
            <li>
              <Link to="/cennik/">Cennik</Link>
            </li>
            <li>
              <Link to="/gabinet/">Gabinet</Link>
            </li>
            <li>
              <Link to="/hobby/">Hobby</Link>
            </li>
          </NavItems>
          <ContactNumber>+48 74 831 19 10</ContactNumber>
          <Button as="a" href="tel:748311910">
            <img src={callicon} alt="zadzwoń do nas" /> Umów Wizytę
          </Button>
        </MainNavigation>
        <NavItems className={isActiveNav && "active"}>
          <li>
            <Link to="/">Strona główna</Link>
          </li>
          <li>
            <Link to="/cennik/">Cennik</Link>
          </li>
          <li>
            <Link to="/gabinet/">Gabinet</Link>
          </li>
          <li>
            <Link to="/hobby/">Hobby</Link>
          </li>
          <li style={{ width: "100%" }}>
            <Button as="a" href="tel:748311910" className="mobile-btn">
              <img src={callicon} alt="zadzwoń do nas" /> Umów Wizytę
            </Button>
          </li>
        </NavItems>
      </div>
    </StyledNav>
  )
}

export default Navigation
