export const theme = {
  color: {
    primary: "#101c35", // dark
    secondary: "#255ed6", //blue
    dark2: "#1b273f", //dark2
    orange: " linear-gradient(to left, #f25d50, #fd7468)",
    blue: "linear-gradient(to left, #167ee6, #255ed6)",
  },
  font: {
    xxs: "12px",
    xs: "16px",
    s: "18px",
    m: "24px",
    l: "30px",
    xl: "36px",
    xxl: "48px",
  },
}
