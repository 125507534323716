import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

html,body, *::after, *::before{
    margin:0;
    padding:0;
    display:block;
    box-sizing: border-box;
    font-family: "Ubuntu";
    font-weight: 400;
    overflow-x:hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body{
  background-color: #f4f7fb;
}
p{
    font-size: 16px;
    line-height: 25px;
    margin-block-start: 0;
    margin-block-end: 0;
    color: #101c35;
}

h1,h2,h3{
  margin-block-start: 0;
  margin-block-end: 0;
}

ul{
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding:0;
}

button{
    border: none;
    background-color: transparent;
}

img{
    height: auto;
    max-width:100%;
}
a {
    color: #474dc4;
    display: inline-block;
    text-decoration: none;
}
button{
    &:focus{
        outline: 0; 
    }
}
.container{
    margin: 0 auto;
    padding: 0 16px;
    @media(min-width:768px){
        max-width:576px;
    }
    @media(min-width: 1024px){
        max-width: 992px;
    }
    @media(min-width:1200px){
        max-width: 1170px;
    }
    @media(min-width: 1400px){
        max-width:1200px;
    }
}
`

export default GlobalStyle
