import styled from "styled-components"
import { media } from "styles/media"

export const StyledSubtitle = styled.h3`
  font-size: ${props => props.theme.font.xs};
  color: ${props => props.theme.color.secondary};
  ${media.pc} {
    font-size: 20px;
  }
`
