const em = px => `${px / 16}em`
const mountMedia = val => `@media screen and (min-width: ${em(val)})`

export const breakpoints = {
  mobile: 576,
  tablet: 768,
  pc: 1024,
  pcBig: 1200,
}

export const media = {
  mobile: mountMedia(breakpoints.mobile),
  tablet: mountMedia(breakpoints.tablet),
  pc: mountMedia(breakpoints.pc),
  pcBig: mountMedia(breakpoints.pcBig),
}
